<template>
    <div>
        <el-dialog :visible.sync="sta.show" width="800px">
            <span slot="title">修改领用申请</span>
            <el-button type="primary" @click="add()">添加物品</el-button>
            <wgrid :data="model.GITEMS" :pageShow="false">
                <el-table-column prop="STORAGE_NAME" label="仓库" width="120" />
                <el-table-column prop="CAT_NAME" label="分类" width="120" />
                <el-table-column prop="ST_NAME" label="物品" width="200" />
                <el-table-column prop="ST_UNIT" label="单位" width="100" />
                <el-table-column prop="CNT" label="数量" width="100" />
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="text" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </wgrid>
            <div class="col-md-12">
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label><b>位置</b></label>
                            <div>
                                <el-cascader ref="loc" v-model="loc.val" :options="loc.ls" placeholder="请选择位置"
                                    :disabled="!!model.CLASS_ID" clearable
                                    :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-group">
                            <label><b>归属班级</b></label><br>
                            <el-select v-model="model.CLASS_ID" :disabled="loc.val.length != 0" clearable>
                                <el-option v-for="item in cla.ls" :key="item.ID" :label="item.NAME" :value="item.ID">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer">
                <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="save">确认</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="eShow" width="800px">
            <span slot="title">修改{{body.ST_NAME}}</span>
            <div class="row">
                <div class="col-md-12">
                    <el-row>
                        <el-col :span="8">
                            <div class="form-group">
                                <label><b>仓库名称</b></label>
                                <el-select v-model="body.STORAGE_ID" placeholder="请选择仓库" @change="changeStorage">
                                    <el-option :label="it.STORAGE_NAME" :value="it.ID" v-for="it in store.ls"
                                        :key="it.ID" />
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="form-group">
                                <label><b>分类</b></label><br />
                                <el-cascader :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }"
                                    @change="changeCat" v-model="cat.val" :options="cat.ls" placeholder="请选择分类" />
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="form-group">
                                <label><b>物品</b></label><br />
                                <el-select v-model="body.ST_ID" placeholder="请选择物品" @change="changeStock" filterable>
                                    <el-option v-for="item in stock.ls" :key="item.ID" :label="item.NAME"
                                        :value="item.ID">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-col>

                    </el-row>

                    <el-row>
                        <el-col :span="8">
                            <div class="form-group">
                                <label>
                                    <b>数量</b>
                                </label>
                                <div>
                                    <el-input-number v-model="body.CNT" :step="1"></el-input-number>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="form-group">
                                <label><b>库存量</b></label>
                                <div>
                                    <el-input v-model="stock.ts.BALANCE" readonly style="width: 60%;">
                                        <template slot="prepend"><i class="el-icon-coin" /></template>
                                    </el-input>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <div class="form-group">
                                <label><b>备注</b></label>
                                <el-input type="text" v-model="body.DESC" />
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div slot="footer">
                <el-button icon="el-icon-close" @click="eShow = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Wgrid from "@/components/wgrid";
    export default {
        name: "index",
        components: { Wgrid },
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                eShow: false,
                model: {},
                store: {
                    ls: [],
                },
                cat: {
                    all: [],
                    ls: [],
                    val: []
                },
                stock: {
                    all: [],
                    ls: [],
                    ts: {
                        BALANCE: 0
                    }
                },
                cla: {
                    ls: []
                },
                loc: {
                    ls: [],
                    val: []
                },
                body: {},
                type: "",
            }

        },
        methods: {
            init(data) {
                this.sta = { show: true, loading: true }
                this.model = JSON.parse(JSON.stringify(data));

                if (this.store.ls.length == 0) this.getStore();
                if (this.cat.all.length == 0) this.getCat();
                if (this.cla.ls.length == 0) this.getClass();
                if (this.loc.ls.length == 0) this.getLoc();
                if (this.stock.all.length == 0) this.getStock();

                this.loc.val = []
                if (data.LOCATION) {
                    let names = data.LOCATION.split("/")

                    let ne = names[0]
                    let loc = this.loc.ls.find(x => x.NAME == ne)
                    if (loc) {
                        this.loc.val.push(loc.ID)
                        if (names[1]) {
                            this.loc.val.push(loc.CHILDREN.find(x => x.NAME == names[1]).ID)
                        }
                    }

                }
            },
            getClass() {
                let self = this;
                this.whale.remote.getCollection({
                    url: "/api/School/ORG/ClassApi/GetList",
                    completed: function (its) {
                        self.cla.ls = its;
                    }
                })
            },
            getLoc() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/FIX/LocationApi/GetTree",
                    data: { PAGE_SIZE: 100 },
                    completed: function (its) {
                        self.loc.ls = its.DATA;
                    }
                })
            },
            getStore() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/MAT/MatStorage/GetList",
                    completed: function (its) {
                        self.store.ls = its.DATA;
                    }
                })
            },
            changeStorage() {
                if (this.body.STORAGE_ID > 0) {
                    this.cat.ls = this.cat.all.filter(it => it.STORAGE_ID == this.body.STORAGE_ID);
                } else {
                    this.cat.ls = [];
                }
                this.stock.ls = []
            },
            getCat() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/MAT/MCatApi/GetTree",
                    completed: function (its) {
                        self.cat.all = its.DATA;
                    }
                })
            },
            changeCat() {
                if (this.cat.val.length > 0) {
                    this.body.CAT_ID = this.cat.val[0]
                    this.stock.ls = this.stock.all.filter(it => it.CAT_ID == this.body.CAT_ID);
                } else {
                    this.stock.ls = []
                }
            },
            getStock() {
                let self = this;
                this.whale.remote.getCollection({
                    url: "/api/School/MAT/StockApi/GetList",
                    completed: function (its, n) {
                        self.stock.all = its;
                    }
                })
            },
            changeStock(v) {
                let self = this;
                self.stock.ts = self.stock.all.find(it => it.ID == v)
                if (self.stock.ts) {
                    self.body.ST_NAME = self.stock.ts.NAME;
                    self.body.ST_UNIT = self.stock.ts.FUNIT;
                    self.body.CAT_NAME = self.stock.ts.CAT_NAME;
                    self.body.STORAGE_NAME = self.stock.ts.STORAGE_NAME;
                }
            },

            save() {
                if (this.loc.val.length > 0) {
                    let locNode = this.$refs.loc.getCheckedNodes()[0];
                    this.model.LOCATION = locNode.data.FULL_NAME;
                    this.model.CLASS_ID = 0;
                    this.model.CLASS_NAME = ""
                } else if (this.model.CLASS_ID > 0) {
                    this.model.CLASS_NAME = this.cla.ls.find(x => x.ID == this.model.CLASS_ID).NAME
                    this.model.LOCATION = ""
                }

                if (!this.model.LOCATION && this.model.CLASS_ID == 0) {
                    this.$message.warning('请选择位置或者班级')
                    return
                }
                if (this.model.GITEMS.length == 0) {
                    this.$message.warning('没有申请领用物品')
                    return
                }
                const uniqueIds = new Set(this.model.GITEMS.map(x => x.STORAGE_ID));
                if (uniqueIds.size > 1) {
                    this.$message.warning('申请领用物品必须同一个仓库')
                    return
                }

                this.model.GBODY = JSON.stringify(this.model.GITEMS)

                let self = this;
                self.whale.remote.getResult({
                    url: "/api/School/MAT/MVActApi/Update",
                    data: self.model,
                    finally() {
                        self.sta.loading = false;
                    },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                })
            },


            add() {
                this.type = 'add'
                this.body = {
                    ST_ID: "",
                    CAT_ID: "",
                    STORAGE_ID: "",
                    CNT: 0,
                    AMT: 0
                }
                this.cat.val = []
                this.stock.ts = {}
                this.eShow = true
            },
            edit(r) {
                this.type = 'edit'
                this.body = r
                if (r.STORAGE_ID > 0)
                    this.cat.ls = this.cat.all.filter(it => it.STORAGE_ID == r.STORAGE_ID)
                if (r.CAT_ID > 0) {
                    this.cat.val = [r.CAT_ID]
                    this.stock.ls = this.stock.all.filter(it => it.CAT_ID == r.CAT_ID)
                }
                if (r.ST_ID > 0)
                    this.stock.ts = this.stock.ls.find(it => it.ID == r.ST_ID)

                this.eShow = true
            },
            del(r) {
                this.$confirm("确定要删除 " + r.ST_NAME + " 吗？", "取消", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.model.GITEMS = this.model.GITEMS.filter(x => x.ST_ID != r.ST_ID)
                }).catch(() => { })
            },
            submit() {
                if (this.body.STORAGE_ID == 0 || this.body.CAT_ID == 0 || this.body.ST_ID == 0) {
                    this.$message.warning('请选择物品')
                    return
                }
                if (this.body.CNT == 0) {
                    this.$message.warning('请选择领用数量')
                    return
                } else if (this.body.CNT > this.stock.ts.BALANCE) {
                    this.$message.warning('库存不足')
                    return
                }

                this.body.AMT = this.stock.ts.PRICE * this.body.CNT;
                if (this.type == 'add') {
                    if (this.model.GITEMS.some(x => x.ST_ID === this.body.ST_ID)) {
                        this.$message.warning('请勿重复选择')
                        return
                    }
                    this.model.GITEMS.push(this.body)
                }

                // this.$message.success(this.body.ST_NAME + ' 添加成功')
                this.eShow = false
            },
        }
    }
</script>