<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">二维码</span>
            <div class="row">
                <div class="col-md-12" style="text-align: center;">
                    <img :src="'data:image/png;base64,' + img" alt="">
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="downERCode">下载</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                img: ''
            }
        },
        mounted() { },
        methods: {
            init() {
                this.sta = { show: true, loading: false }
                this.getQrCode()
            },
            getQrCode() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/MAT/MVActApi/ShowHealthMVActQrcode",
                    completed: function (its) {
                        console.log(its)
                        self.img = its.DATA
                    }
                })
            },
            downERCode() {
                // this.whale.downLoad(this.img)
                this.downLoadBase64('二维码.png', this.img)
            },
            downLoadBase64(filename, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);

                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = filename;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
            }
            ,
            base64ToBlob(code) {
                let contentType = code.split(':')[1];
                let raw = window.atob(code);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], { type: contentType });
            }
        }
    }
</script>