<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新的申请单</span>
      <div class="row">
        <div class="col-md-12">
          <el-table :data="grid" stripe border :cell-style="{ padding: '0px' }"
            :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
            <el-table-column prop="ST_NAME" label="物品名称" width="300" />
            <el-table-column prop="ST_UNIT" label="单位" width="60" align="center" />
            <el-table-column prop="CNT" label="数量" width="80" align="center" />
            <el-table-column prop="AMT" label="单价(元)" width="80" align="center">
              <template slot-scope="scope">
                ￥{{ scope.row.AMT.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="总金额(元)" width="100" align="right">
              <template slot-scope="scope">
                ￥{{ scope.row.AMT_TOTAL.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="DESC" label="备注" width="200" />
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-document" @click="del(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <div style="float: left; font-size: 16px;padding: 10px 50px">
          <span><b>合计：</b> ￥{{ model.AMT.toFixed(2) }} 元</span>
        </div>
        <el-button icon="el-icon-plus" type="primary" @click="add">增加记录</el-button>
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认提交</el-button>
      </div>
    </el-dialog>
    <div>
      <Gitem ref="gitem" @on-saved="addCallback" />
    </div>
  </div>
</template>
<script>
  import Gitem from "./gitem"
  export default {
    components: { Gitem },
    data() {
      return {
        sta: {
          show: false,
          loading: false
        },
        grid: [],
        model: { AMT: 0, CAT: 100 },
        cat: {
          ls: [],
          val: []
        },
        ls_stock: [],
      }
    },
    methods: {
      init(cat) {
        this.sta = { show: true, loading: false }
        this.model = { AMT: 0, CAT: cat };
        this.grid = [];
      },
      add() {
        this.$refs.gitem.init(this.model.CAT);
      },
      addCallback(o) {
        this.model.AMT += Number(o.AMT_TOTAL);
        this.grid.push(o);
      },
      del(o) {
        let amt = 0;
        this.grid = this.grid.filter(x => {
          if (x.ST_ID != o.ST_ID) {
            amt += x.AMT;
            return true;
          } else
            return false;
        })
        this.model.AMT = amt;
      },
      submit() {
        let self = this;
        if (this.grid.length == 0) {
          this.whale.toast.err("没有物品")
          return
        }
        // if (this.grid.length>5){
        //   this.whale.toast.err("对不起，一次最多只能提交5条记录")
        //   return
        // }
        let tt = [];
        this.grid.map(x => {
          tt.push(x.ST_NAME);
          x.RAMT = x.AMT;
        })
        this.model.TITLE = tt.join();
        this.model.GBODY = JSON.stringify(this.grid);
        self.sta.loading = true;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MVActApi/Create",
          data: self.model,
          finally() { self.sta.loading = false; },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved");
          }
        })
      }
    }
  }
</script>